import Vue from 'vue';

const container = document.getElementById('local-detail');
const exists = typeof (container) != 'undefined' && container != null;

import LocalDetail from './LocalDetail.vue';

if (exists) {
    new Vue({
        el: '#local-detail',
        components: {
            LocalDetail,
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    });
};