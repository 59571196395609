var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list
    ? _c(
        "div",
        { staticClass: "suppliers-local" },
        [
          _c("div", { staticClass: "suppliers-local__filter" }, [
            _c(
              "select",
              {
                staticClass: "js-select",
                attrs: { "data-placeholder": "Тип закупки" },
                on: { change: _vm.filterHandler }
              },
              [
                _c("option"),
                _vm._v(" "),
                _vm._l(_vm.filter.type, function(item, index) {
                  return _c("option", {
                    key: index,
                    domProps: { value: item.id, innerHTML: _vm._s(item.text) }
                  })
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "suppliers-local__list" },
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "div",
                { key: index, staticClass: "suppliers-local__item" },
                [
                  _c("span", {
                    staticClass: "suppliers-local__date",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.formatDate(item.publicationDate.timestamp)
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("a", {
                    staticClass: "suppliers-local__title link",
                    attrs: { href: item.detailPageUrl },
                    domProps: { innerHTML: _vm._s(item.name) }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "suppliers-local__additional additional" },
                    [
                      item.number.value
                        ? _c("div", { staticClass: "additional__item" }, [
                            _c("div", { staticClass: "additional__title" }, [
                              _vm._v("Номер лота:")
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "additional__value",
                              domProps: { innerHTML: _vm._s(item.number.value) }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.type.value
                        ? _c("div", { staticClass: "additional__item" }, [
                            _c("div", { staticClass: "additional__title" }, [
                              _vm._v("Тип закупки:")
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "additional__value",
                              domProps: { innerHTML: _vm._s(item.type.value) }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.endDate.timestamp
                        ? _c("div", { staticClass: "additional__item" }, [
                            _c("div", { staticClass: "additional__title" }, [
                              _vm._v("Приём заявок до:")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "additional__value" }, [
                              _vm._v(
                                _vm._s(_vm.formatDate(item.endDate.timestamp))
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.isLoading ? _c("z-preloader") : _vm._e(),
          _vm._v(" "),
          _vm.paginationData
            ? _c("z-pagination", {
                staticClass: "u-top-margin--3xl-important",
                attrs: {
                  "show-next": "",
                  "show-prev": "",
                  data: _vm.paginationData
                },
                on: { "change-page": _vm.paginationHandler }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-50efb686", { render: render, staticRenderFns: staticRenderFns })
  }
}