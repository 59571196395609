var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("div", { staticClass: "suppliers-local-detail" }, [
        _vm.data.publicationDate.timestamp ||
        _vm.data.type.value ||
        _vm.data.endDate.timestamp ||
        _vm.data.contactName.value
          ? _c("section", { staticClass: "suppliers-local-detail__section" }, [
              _vm.data.publicationDate.timestamp
                ? _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "info-item__title" }, [
                      _vm._v("Дата публикации:")
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "info-item__description",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatDate(_vm.data.publicationDate.timestamp)
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.data.type.value
                ? _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "info-item__title" }, [
                      _vm._v("Тип закупки:")
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "info-item__description",
                      domProps: { innerHTML: _vm._s(_vm.data.type.value) }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.data.endDate.timestamp
                ? _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "info-item__title" }, [
                      _vm._v("Дата окончания приёма заявок:")
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "info-item__description u-medium",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formatDate(_vm.data.endDate.timestamp)
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.data.contactName.value
                ? _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "info-item__title" }, [
                      _vm._v("Контакты для связи:")
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "info-item__description",
                      domProps: {
                        innerHTML: _vm._s(_vm.data.contactName.value)
                      }
                    })
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.detailText && _vm.data.detailText.length
          ? _c("section", { staticClass: "suppliers-local-detail__section" }, [
              _c("h2", { staticClass: "u-bottom-margin--s" }, [
                _vm._v("Описание")
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "u-collapse--top",
                domProps: { innerHTML: _vm._s(_vm.data.detailText) }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm.data.files && _vm.data.files.length
          ? _c("section", { staticClass: "suppliers-local-detail__section" }, [
              _c("h2", { staticClass: "u-bottom-margin--s" }, [
                _vm._v("Файлы")
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "file-list file-list__group u-collapse--top" },
                _vm._l(_vm.data.files, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: "file-list__item file-list__item--" + item.type
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link file-list__link",
                          attrs: { href: item.link, target: "_blank" }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.name) +
                              "\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "file-list__size" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.type) +
                            ", " +
                            _vm._s(item.size) +
                            "\n                "
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "suppliers-local-detail__section detail-attention" },
      [
        _c("p", { staticClass: "detail-attention__text" }, [
          _vm._v(
            "Данная публикация не является официальным документом, объявляющим о начале Закупочной процедуры и не должна рассматриваться как предложение заключения договора. Не проведение анонсированной закупки не может быть основанием для предъявления каких‑либо претензий к Обществу."
          )
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5c17044a", { render: render, staticRenderFns: staticRenderFns })
  }
}