var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("div", { staticClass: "projects-detail" }, [
        _c("div", {
          staticClass: "projects-detail__city",
          domProps: { innerHTML: _vm._s(_vm.data.city.value) }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "projects-detail__text",
          domProps: { innerHTML: _vm._s(_vm.data.detailText) }
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "link link--arrow-back u-collapse--top",
            attrs: { href: "/company/projects/" }
          },
          [_vm._v("Назад к списку")]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-70a9cd40", { render: render, staticRenderFns: staticRenderFns })
  }
}