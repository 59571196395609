<template>
    <figure class="project-card">
        <a
            :href="data.detailPageUrl"
            class="project-card__wrapper"
        >
            <div
                class="project-card__image"
                :style="`background-image: url(${data.image.link})`"
            />
            <div class="project-card__tag" v-html="data.city.value" />
            <figcaption class="project-card__caption" v-html="data.name" />
        </a>
    </figure>
</template>

<script>
export default {
    name: 'project-card',
    props: {
        data: {
            type: Object
        }
    }
}
</script>
