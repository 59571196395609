var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list && _vm.list.length
    ? _c(
        "div",
        { staticClass: "vacancies" },
        [
          _c("div", { staticClass: "vacancies__filter" }, [
            _c(
              "select",
              { staticClass: "js-select", on: { change: _vm.filterHandler } },
              [
                _c("option", { attrs: { value: "" } }, [_vm._v("Все города")]),
                _vm._v(" "),
                _vm._l(_vm.filter.city, function(item, index) {
                  return _c("option", {
                    key: index,
                    domProps: { value: item.id, innerHTML: _vm._s(item.text) }
                  })
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "vacancies__list" },
            _vm._l(_vm.list, function(item, index) {
              return _c("div", { key: index, staticClass: "vacancies__item" }, [
                _c("span", {
                  staticClass: "vacancies__city",
                  domProps: { innerHTML: _vm._s(item.city.value) }
                }),
                _vm._v(" "),
                _c("a", {
                  staticClass: "vacancies__title text-size-xl link",
                  attrs: { href: item.detailPageUrl },
                  domProps: { innerHTML: _vm._s(item.name) }
                })
              ])
            }),
            0
          ),
          _vm._v(" "),
          _vm.isLoading ? _c("z-preloader") : _vm._e(),
          _vm._v(" "),
          _vm.paginationData
            ? _c("z-pagination", {
                staticClass: "u-top-margin--3xl-important",
                attrs: {
                  "show-next": "",
                  "show-prev": "",
                  data: _vm.paginationData
                },
                on: { "change-page": _vm.paginationHandler }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b6b39c6", { render: render, staticRenderFns: staticRenderFns })
  }
}