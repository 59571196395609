import Vue from 'vue';

const container = document.getElementById('local-page');
const exists = typeof (container) != 'undefined' && container != null;

import LocalPage from './LocalPage.vue';

if (exists) {
    new Vue({
        el: '#local-page',
        components: {
            LocalPage,
        },
        data: {
            lang: getLang(),
            app: window.App
        },
    });
};