<template>
    <div class="suppliers-local-detail" v-if="data">
        <section
            class="suppliers-local-detail__section"
            v-if="data.publicationDate.timestamp ||
                data.type.value ||
                data.endDate.timestamp ||
                data.contactName.value
            "
        >
            <div class="info-item" v-if="data.publicationDate.timestamp">
                <span class="info-item__title">Дата публикации:</span>
                <span class="info-item__description" v-html="formatDate(data.publicationDate.timestamp)" />
            </div>
            <div class="info-item" v-if="data.type.value">
                <span class="info-item__title">Тип закупки:</span>
                <span class="info-item__description" v-html="data.type.value" />
            </div>
            <div class="info-item" v-if="data.endDate.timestamp">
                <span class="info-item__title">Дата окончания приёма заявок:</span>
                <span class="info-item__description u-medium" v-html="formatDate(data.endDate.timestamp)" />
            </div>
            <div class="info-item" v-if="data.contactName.value">
                <span class="info-item__title">Контакты для связи:</span>
                <span class="info-item__description" v-html="data.contactName.value" />
            </div>
        </section>
        <section class="suppliers-local-detail__section" v-if="data.detailText && data.detailText.length">
            <h2 class="u-bottom-margin--s">Описание</h2>
            <div class="u-collapse--top" v-html="data.detailText" />
        </section>
        <section class="suppliers-local-detail__section detail-attention">
            <p class="detail-attention__text">Данная публикация не&nbsp;является официальным документом, объявляющим о&nbsp;начале Закупочной процедуры и&nbsp;не&nbsp;должна рассматриваться как предложение заключения договора. Не&nbsp;проведение анонсированной закупки не&nbsp;может быть основанием для предъявления каких‑либо претензий к&nbsp;Обществу.</p>
        </section>
        <section class="suppliers-local-detail__section" v-if="data.files && data.files.length">
            <h2 class="u-bottom-margin--s">Файлы</h2>
            <ul class="file-list file-list__group u-collapse--top">
                <li
                    :class="`file-list__item file-list__item--${item.type}`"
                    v-for="(item, index) in data.files"
                    :key="index"
                >
                    <a
                        :href="item.link"
                        class="link file-list__link"
                        target="_blank"
                    >
                        {{ item.name }}
                    </a>
                    <span class="file-list__size">
                        {{ item.type}}, {{ item.size }}
                    </span>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
import moment from 'moment';
import { momentFilter } from '../../utils/filter.js';

export default {
    name: 'suppliers-local-detail',
    created () {
        moment.locale('ru')
    },
    data () {
        return {
            data: this.$root.app.components['suppliers-local-detail']
        }
    },
    methods: {
        formatDate (date) {
            return momentFilter(date, 'DD.MM.YYYY')
        }
    }
}
</script>