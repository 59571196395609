var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items && _vm.items.length
    ? _c(
        "div",
        { staticClass: "projects-page" },
        [
          _vm.nav
            ? _c("div", { staticClass: "projects-page__filter" }, [
                _c(
                  "select",
                  {
                    staticClass: "js-select",
                    on: { change: _vm.filterHandler }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Все города")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.filters.city, function(item, index) {
                      return _c("option", {
                        key: index,
                        domProps: {
                          value: item.id,
                          innerHTML: _vm._s(item.text)
                        }
                      })
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.items
            ? _c(
                "div",
                { ref: "container", staticClass: "projects-page__cards-list" },
                [_c("results", { attrs: { data: _vm.items } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading ? _c("z-preloader") : _vm._e(),
          _vm._v(" "),
          _vm.paginationData
            ? _c("z-pagination", {
                staticClass: "u-top-margin--3xl-important",
                attrs: {
                  "show-next": "",
                  "show-prev": "",
                  data: _vm.paginationData
                },
                on: { "change-page": _vm.paginationHandler }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-494e13a0", { render: render, staticRenderFns: staticRenderFns })
  }
}