<template>
    <div class="vacancies" v-if="list && list.length">
        <div class="vacancies__filter">
            <select class="js-select" @change="filterHandler">
                <option value="">Все города</option>
                <option
                    v-for="(item, index) in filter.city"
                    :key="index"
                    :value="item.id"
                    v-html="item.text"
                />
            </select>
        </div>
        <div class="vacancies__list">
            <div class="vacancies__item" v-for="(item, index) in list" :key="index">
                <span class="vacancies__city" v-html="item.city.value" />
                <a class="vacancies__title text-size-xl link" :href="item.detailPageUrl" v-html="item.name" />
            </div>
        </div>
        <z-preloader v-if="isLoading" />
        <z-pagination
            v-if="paginationData"
            class="u-top-margin--3xl-important"
            show-next
            show-prev
            :data="paginationData"
            @change-page="paginationHandler"
        >
        </z-pagination>
    </div>
</template>

<script>
import {throttle} from 'throttle-debounce'
const qs = require('query-string')
import ZPagination from '../app/ZPagination/ZPagination.vue'
import ZPreloader from '../app/ZPreloader/ZPreloader.vue'

export default {
    name: 'vacancies-page',
    components: {
        ZPagination,
        ZPreloader
    },
    data () {
        return {
            isLoading: false,
            params: {
                city: null,
                page: 1
            },
            nav: this.$root.app.components?.vacancies?.nav || null,
            list: this.$root.app.components?.vacancies?.items || null,
            filter: this.$root.app.components?.vacancies?.filter || []
        }
    },
    mounted () {
        $('.js-select')
            .select2({
                theme: 'filled',
                minimumResultsForSearch: Infinity
            })
            .on('select2:select', this.filterHandler);
    },
    methods: {
        filterHandler (e) {
            let data = e.params.data;
            this.params.city = data.id
            this.params.page = 1
            this.send()
        },
        paginationHandler (data) {
            this.params.page = data.page
            this.send()
        },
        send: throttle(500, function () {
            this.isLoading = true

            let queryParams = qs.stringify(this.params)

            fetch(`/api/vacancies/?${queryParams}`)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        let error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    };
                })
                .then(response => {
                    this.list = response.items
                    this.nav = response.nav
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false
                })
        })
    },
    computed: {
        paginationData () {
            if (this.nav) {
                return ({
                    currentPage: this.nav?.current,
                    totalItems: this.nav?.count,
                    totalPages: this.nav?.total,
                    pageRange: 1
                })
            }

            return null
        }
    }
}
</script>
