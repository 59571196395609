<template>
    <div class="vacancies-detail" v-if="data">
        <section class="vacancies-detail__header" v-if="data.city && data.city.value">
            <div class="info-item">
                <span class="info-item__title">Город:</span>
                <span class="info-item__description" v-html="data.city.value"></span>
            </div>
        </section>
        <section
            class="vacancies-detail__expect"
            v-if="data.education && data.education.value ||
                data.experience && data.experience.value ||
                data.requirements && data.requirements.value
            "
        >
            <h2 class="vacancies-detail__title">Мы ожидаем от вас</h2>
            <div
                class="info-item"
                v-if="data.education && data.education.value"
            >
                <span class="info-item__title">Образование:</span>
                <span class="info-item__description" v-html="data.education.value"></span>
            </div>
            <div
                class="info-item"
                v-if="data.experience && data.experience.value"
            >
                <span class="info-item__title">Опыт работы:</span>
                <span class="info-item__description" v-html="data.experience.value"></span>
            </div>
            <div
                class="info-item"
                v-if="data.requirements && data.requirements.value"
            >
                <span class="info-item__title">Профессиональные требования:</span>
                <span class="info-item__description" v-html="data.requirements.value"></span>
            </div>
        </section>
        <section
            class="vacancies-detail__tasks"
            v-if="data.tasks && data.tasks.value"
        >
            <h2 class="vacancies-detail__title">Ваши основные задачи</h2>
            <div v-html="data.tasks.value"></div>
        </section>
        <section
            class="vacancies-detail__conditions"
            v-if="data.conditions && data.conditions.value"
        >
            <h2 class="vacancies-detail__title">Условия работы</h2>
            <div v-html="data.conditions.value"></div>
        </section>
        <section
            class="vacancies-detail__additional"
            v-if="data.addDescription && data.addDescription.value"
        >
            {{ data.addDescription.value }}
        </section>
        <section
            class="vacancies-detail__contacts vacancies-contacts"
            v-if="data.email && data.email.length ||
                data.phone && data.phone.length
            "
        >
            <div
                class="vacancies-contacts__mail"
                v-if="data.email && data.email.length"
            >
                <h3 class="vacancies-contacts__title">Резюме направлять на почту:</h3>
                <span v-for="(item, index) in data.email" :key="index" class="vacancies-contacts__item">
                    <a
                        v-if="item.value"
                        :href="`mailto:${item.value}`"
                        class="vacancies-contacts__link vacancies-contacts__link--mail link"
                        v-html="item.value"
                    />
                </span>
            </div>
            <div
                class="vacancies-contacts__phone"
                v-if="data.phone && data.phone.length"
            >
                <h3 class="vacancies-contacts__title">Телефон для справок:</h3>
                <span 
                    v-if="data.contactPerson.value" 
                    v-html="data.contactPerson.value" 
                    class="vacancies-contacts__person"
                ></span>
                <span v-for="(item, index) in data.phone" :key="index" class="vacancies-contacts__item">
                    <a
                        v-if="item.value"
                        :href="`tel:${item.description && item.description !== '' ? item.description : item.value}`"
                        class="vacancies-contacts__link vacancies-contacts__link--phone link"
                        v-html="item.value"
                    />
                </span>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'vacancies-detail',
    data () {
        return {
            data: this.$root.app.components['vacancies-detail'].item
        }
    }
}
</script>

<style lang="scss">
.vacancies-detail {
    &__expect,
    &__tasks,
    &__conditions,
    &__additional,
    &__header {
        margin-bottom: $token-spacers-xl;

        @include breakpoint (mobile) {
            margin-bottom: $token-spacers-m;
        }
    }

    &__title {
        color: $token-colors-black-90 !important;
        margin-bottom: $token-spacers-xs;
        margin-top: 0;
    }

    .vacancies-contacts {
        background: #ECEEF2;
        border-radius: 40px 0px 0px 0px;
        padding: $token-spacers-m $token-spacers-xl;
        display: flex;
        flex-wrap: wrap;
        gap: 72px;

        &__item {
            margin: 0;
        }

        @include breakpoint (mobile) {
            flex-direction: column;
            padding: $token-spacers-s;
            gap: $token-spacers-s;
        }

        &__title {
            margin-bottom: $token-spacers-2-xs;
            color: $token-colors-black-90;
            font-weight: 600;
            font-size: 18px;
        }

        &__phone,
        &__mail {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
        }

        &__person {
            margin-top: 0;
            display: flex;
            align-items: center;
            margin-bottom: $token-spacers-3-xs;

            &:before {
                content: '';
                width: 24px;
                height: 24px;
                display: block;
                margin-right: $token-spacers-2-xs;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.75 9.5C6.75 6.6005 9.10051 4.25 12 4.25C14.8995 4.25 17.25 6.6005 17.25 9.5C17.25 12.3995 14.8995 14.75 12 14.75C12 14.75 12 14.75 12 14.75C9.10051 14.75 6.75 12.3995 6.75 9.5ZM15.462 15.2958C17.4315 14.1168 18.75 11.9624 18.75 9.5C18.75 5.77208 15.7279 2.75 12 2.75C8.27208 2.75 5.25 5.77208 5.25 9.5C5.25 11.9624 6.56848 14.1168 8.53796 15.2957C7.78814 15.5382 7.0628 15.8597 6.37464 16.2568C4.66421 17.244 3.24367 18.6639 2.25574 20.3739C2.04853 20.7326 2.17131 21.1913 2.52997 21.3985C2.88863 21.6057 3.34735 21.483 3.55457 21.1243C4.41081 19.6422 5.642 18.4116 7.12445 17.556C8.60689 16.7004 10.2884 16.25 12 16.25C12 16.25 12 16.25 12 16.25C13.7117 16.25 15.3932 16.7005 16.8756 17.5561C18.358 18.4117 19.5892 19.6424 20.4454 21.1245C20.6526 21.4831 21.1113 21.6059 21.47 21.3987C21.8287 21.1915 21.9514 20.7328 21.7442 20.3741C20.7563 18.6641 19.3358 17.2442 17.6254 16.257C16.9372 15.8597 16.2119 15.5382 15.462 15.2958Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
            }
        }

        &__link {
            display: flex;
            margin-top: 0;

            + .vacancies-contacts__link {
                margin-top: $token-spacers-3-xs;
            }

            &:before {
                content: '';
                width: 24px;
                height: 24px;
                display: block;
                margin-right: $token-spacers-2-xs;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            &--mail {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 4.5C2.58579 4.5 2.25 4.83579 2.25 5.25V18C2.25 18.3978 2.40804 18.7794 2.68934 19.0607C2.97065 19.342 3.35218 19.5 3.75 19.5H20.25C20.6478 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V5.25C21.75 4.83579 21.4142 4.5 21 4.5H3ZM3.75 18V6H20.25V18H3.75Z' fill='%230077C8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.5068 4.69714C3.20146 4.41724 2.72703 4.43787 2.44714 4.74321C2.16724 5.04855 2.18787 5.52298 2.49321 5.80287L11.4932 14.0529C11.78 14.3157 12.2201 14.3157 12.5068 14.0529L21.5068 5.80287C21.8121 5.52298 21.8328 5.04855 21.5529 4.74321C21.273 4.43787 20.7985 4.41724 20.4932 4.69714L12 12.4826L3.5068 4.69714Z' fill='%230077C8'/%3E%3C/svg%3E%0A");
                }

                &:hover {
                    &:before {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 4.5C2.58579 4.5 2.25 4.83579 2.25 5.25V18C2.25 18.3978 2.40804 18.7794 2.68934 19.0607C2.97065 19.342 3.35218 19.5 3.75 19.5H20.25C20.6478 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V5.25C21.75 4.83579 21.4142 4.5 21 4.5H3ZM3.75 18V6H20.25V18H3.75Z' fill='%23005F94'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.5068 4.69714C3.20146 4.41724 2.72703 4.43787 2.44714 4.74321C2.16724 5.04855 2.18787 5.52298 2.49321 5.80287L11.4932 14.0529C11.78 14.3157 12.2201 14.3157 12.5068 14.0529L21.5068 5.80287C21.8121 5.52298 21.8328 5.04855 21.5529 4.74321C21.273 4.43787 20.7985 4.41724 20.4932 4.69714L12 12.4826L3.5068 4.69714Z' fill='%23005F94'/%3E%3C/svg%3E%0A");
                    }
                }
            }

            &--phone {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.66965 12.2014C9.44762 13.7919 10.7369 15.0753 12.3309 15.846C12.4475 15.9013 12.5765 15.9252 12.7052 15.9155C12.8339 15.9058 12.9579 15.8627 13.0648 15.7905L15.4119 14.2254C15.5157 14.1562 15.6352 14.1139 15.7594 14.1025C15.8837 14.0911 16.0088 14.1109 16.1235 14.16L20.5144 16.0419C20.6636 16.1052 20.7881 16.2154 20.8693 16.3556C20.9504 16.4959 20.9838 16.6588 20.9643 16.8197C20.8255 17.9057 20.2956 18.9039 19.4739 19.6273C18.6521 20.3508 17.5948 20.7499 16.5 20.75C13.1185 20.75 9.87548 19.4067 7.48439 17.0156C5.0933 14.6245 3.75 11.3815 3.75 7.99997C3.75006 6.90513 4.14918 5.84786 4.87264 5.0261C5.5961 4.20435 6.59428 3.67448 7.68028 3.53569C7.84117 3.51622 8.00403 3.54956 8.14432 3.6307C8.28461 3.71183 8.39473 3.83636 8.4581 3.98552L10.3416 8.38032C10.3903 8.494 10.4101 8.61796 10.3994 8.74116C10.3886 8.86436 10.3475 8.98299 10.2798 9.08647L8.72011 11.4696C8.64912 11.5768 8.60716 11.7006 8.59831 11.8288C8.58947 11.9571 8.61405 12.0855 8.66965 12.2014Z' stroke='%230077C8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }

                &:hover {
                    &:before {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.66965 12.2014C9.44762 13.7919 10.7369 15.0753 12.3309 15.846C12.4475 15.9013 12.5765 15.9252 12.7052 15.9155C12.8339 15.9058 12.9579 15.8627 13.0648 15.7905L15.4119 14.2254C15.5157 14.1562 15.6352 14.1139 15.7594 14.1025C15.8837 14.0911 16.0088 14.1109 16.1235 14.16L20.5144 16.0419C20.6636 16.1052 20.7881 16.2154 20.8693 16.3556C20.9504 16.4959 20.9838 16.6588 20.9643 16.8197C20.8255 17.9057 20.2956 18.9039 19.4739 19.6273C18.6521 20.3508 17.5948 20.7499 16.5 20.75C13.1185 20.75 9.87548 19.4067 7.48439 17.0156C5.0933 14.6245 3.75 11.3815 3.75 7.99997C3.75006 6.90513 4.14918 5.84786 4.87264 5.0261C5.5961 4.20435 6.59428 3.67448 7.68028 3.53569C7.84117 3.51622 8.00403 3.54956 8.14432 3.6307C8.28461 3.71183 8.39473 3.83636 8.4581 3.98552L10.3416 8.38032C10.3903 8.494 10.4101 8.61796 10.3994 8.74116C10.3886 8.86436 10.3475 8.98299 10.2798 9.08647L8.72011 11.4696C8.64912 11.5768 8.60716 11.7006 8.59831 11.8288C8.58947 11.9571 8.61405 12.0855 8.66965 12.2014Z' stroke='%23005F94' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }

    .info-item {
        display: flex;
        margin-top: $token-spacers-xs;

        @include breakpoint (mobile) {
            flex-direction: column;
        }

        &__title {
            color: $token-colors-black-40;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.4;
            max-width: 156px;
            width: 100%;
            display: inline-block;
            margin-right: $token-spacers-xs;

            @include breakpoint (mobile) {
                max-width: 100%;
                margin-right: 0;
            }
        }

        &__description {
            color: $token-colors-black-90;
            font-size: 18px;
            font-weight: 400;
            margin-top: 0;
            line-height: 1.4;
        }
    }
}
</style>
