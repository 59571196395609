var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("div", { staticClass: "vacancies-detail" }, [
        _vm.data.city && _vm.data.city.value
          ? _c("section", { staticClass: "vacancies-detail__header" }, [
              _c("div", { staticClass: "info-item" }, [
                _c("span", { staticClass: "info-item__title" }, [
                  _vm._v("Город:")
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "info-item__description",
                  domProps: { innerHTML: _vm._s(_vm.data.city.value) }
                })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        (_vm.data.education && _vm.data.education.value) ||
        (_vm.data.experience && _vm.data.experience.value) ||
        (_vm.data.requirements && _vm.data.requirements.value)
          ? _c("section", { staticClass: "vacancies-detail__expect" }, [
              _c("h2", { staticClass: "vacancies-detail__title" }, [
                _vm._v("Мы ожидаем от вас")
              ]),
              _vm._v(" "),
              _vm.data.education && _vm.data.education.value
                ? _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "info-item__title" }, [
                      _vm._v("Образование:")
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "info-item__description",
                      domProps: { innerHTML: _vm._s(_vm.data.education.value) }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.data.experience && _vm.data.experience.value
                ? _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "info-item__title" }, [
                      _vm._v("Опыт работы:")
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "info-item__description",
                      domProps: { innerHTML: _vm._s(_vm.data.experience.value) }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.data.requirements && _vm.data.requirements.value
                ? _c("div", { staticClass: "info-item" }, [
                    _c("span", { staticClass: "info-item__title" }, [
                      _vm._v("Профессиональные требования:")
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "info-item__description",
                      domProps: {
                        innerHTML: _vm._s(_vm.data.requirements.value)
                      }
                    })
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.tasks && _vm.data.tasks.value
          ? _c("section", { staticClass: "vacancies-detail__tasks" }, [
              _c("h2", { staticClass: "vacancies-detail__title" }, [
                _vm._v("Ваши основные задачи")
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.data.tasks.value) }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.conditions && _vm.data.conditions.value
          ? _c("section", { staticClass: "vacancies-detail__conditions" }, [
              _c("h2", { staticClass: "vacancies-detail__title" }, [
                _vm._v("Условия работы")
              ]),
              _vm._v(" "),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.data.conditions.value) }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data.addDescription && _vm.data.addDescription.value
          ? _c("section", { staticClass: "vacancies-detail__additional" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.data.addDescription.value) + "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        (_vm.data.email && _vm.data.email.length) ||
        (_vm.data.phone && _vm.data.phone.length)
          ? _c(
              "section",
              { staticClass: "vacancies-detail__contacts vacancies-contacts" },
              [
                _vm.data.email && _vm.data.email.length
                  ? _c(
                      "div",
                      { staticClass: "vacancies-contacts__mail" },
                      [
                        _c("h3", { staticClass: "vacancies-contacts__title" }, [
                          _vm._v("Резюме направлять на почту:")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.data.email, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "vacancies-contacts__item"
                            },
                            [
                              item.value
                                ? _c("a", {
                                    staticClass:
                                      "vacancies-contacts__link vacancies-contacts__link--mail link",
                                    attrs: { href: "mailto:" + item.value },
                                    domProps: { innerHTML: _vm._s(item.value) }
                                  })
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.phone && _vm.data.phone.length
                  ? _c(
                      "div",
                      { staticClass: "vacancies-contacts__phone" },
                      [
                        _c("h3", { staticClass: "vacancies-contacts__title" }, [
                          _vm._v("Телефон для справок:")
                        ]),
                        _vm._v(" "),
                        _vm.data.contactPerson.value
                          ? _c("span", {
                              staticClass: "vacancies-contacts__person",
                              domProps: {
                                innerHTML: _vm._s(_vm.data.contactPerson.value)
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.data.phone, function(item, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "vacancies-contacts__item"
                            },
                            [
                              item.value
                                ? _c("a", {
                                    staticClass:
                                      "vacancies-contacts__link vacancies-contacts__link--phone link",
                                    attrs: {
                                      href:
                                        "tel:" +
                                        (item.description &&
                                        item.description !== ""
                                          ? item.description
                                          : item.value)
                                    },
                                    domProps: { innerHTML: _vm._s(item.value) }
                                  })
                                : _vm._e()
                            ]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2c64da0a", { render: render, staticRenderFns: staticRenderFns })
  }
}