import Vue from 'vue';

const container = document.getElementById('vacancies-detail');
const exists = typeof (container) != 'undefined' && container != null;

import VacanciesDetail from './VacanciesDetail.vue';

if (exists) {
    new Vue({
        el: '#vacancies-detail',
        components: {
            VacanciesDetail,
        },
        data: {
            app: window.App
        },
    });
};