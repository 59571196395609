<template>
    <div class="suppliers-local" v-if="list">
        <div class="suppliers-local__filter">
            <select class="js-select" @change="filterHandler" data-placeholder="Тип закупки">
                <option></option>
                <option
                    v-for="(item, index) in filter.type"
                    :key="index"
                    :value="item.id"
                    v-html="item.text"
                />
            </select>
        </div>
        <div class="suppliers-local__list">
            <div class="suppliers-local__item" v-for="(item, index) in list" :key="index">
                <span class="suppliers-local__date" v-html="formatDate(item.publicationDate.timestamp)" />
                <a class="suppliers-local__title link" :href="item.detailPageUrl" v-html="item.name" />
                <div class="suppliers-local__additional additional">
                    <div class="additional__item" v-if="item.number.value">
                        <div class="additional__title">Номер лота:</div>
                        <div class="additional__value" v-html="item.number.value" />
                    </div>
                    <div class="additional__item" v-if="item.type.value">
                        <div class="additional__title">Тип закупки:</div>
                        <div class="additional__value" v-html="item.type.value" />
                    </div>
                    <div class="additional__item" v-if="item.endDate.timestamp">
                        <div class="additional__title">Приём заявок до:</div>
                        <div class="additional__value">{{ formatDate(item.endDate.timestamp) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <z-preloader v-if="isLoading" />
        <z-pagination
            v-if="paginationData"
            class="u-top-margin--3xl-important"
            show-next
            show-prev
            :data="paginationData"
            @change-page="paginationHandler"
        >
        </z-pagination>
    </div>
</template>

<script>
import moment from 'moment';
import { momentFilter } from '../../utils/filter.js';
import {throttle} from 'throttle-debounce'
const qs = require('query-string')
import ZPagination from '../app/ZPagination/ZPagination.vue'
import ZPreloader from '../app/ZPreloader/ZPreloader.vue'

export default {
    name: 'local-page',
    components: {
        ZPagination,
        ZPreloader
    },
    data () {
        return {
            isLoading: false,
            params: {
                type: null,
                page: 1
            },
            nav: this.$root.app.components?.['suppliers-local']?.nav || null,
            list: this.$root.app.components?.['suppliers-local']?.items || null,
            filter: this.$root.app.components?.['suppliers-local']?.filters || {}
        }
    },
    created () {
        moment.locale('ru')
    },
    mounted () {
        $('.js-select')
            .select2({
                theme: 'filled',
                minimumResultsForSearch: Infinity
            })
            .on('select2:select', this.filterHandler);
    },
    methods: {
        formatDate (date) {
            return momentFilter(date, 'DD.MM.YYYY')
        },
        filterHandler (e) {
            let data = e.params.data;
            this.params.type = data.id?.toString()
            this.params.page = 1
            this.send()
        },
        paginationHandler (data) {
            this.params.page = data.page
            this.send()
        },
        send: throttle(500, function () {
            this.isLoading = true

            let queryParams = qs.stringify(this.params)

            fetch(`/api/suppliers-local/?${queryParams}`)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        let error = new Error(response.statusText);
                        error.response = response;
                        throw error;
                    };
                })
                .then(response => {
                    this.list = response.items
                    this.nav = response.nav
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false
                })
        })
    },
    computed: {
        paginationData () {
            if (this.nav) {
                return ({
                    currentPage: this.nav?.current,
                    totalItems: this.nav?.count,
                    totalPages: this.nav?.total,
                    pageRange: 1
                })
            }

            return null
        }
    }
}
</script>
